/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Row, Col, Form, FormGroup, FormControl, ControlLabel, Button, InputGroup } from "react-bootstrap";
import './loginForm.scss'

export class LoginForm extends Component {
  render() {
    return (
        <Form >
            <FormGroup controlId="formControlsSelect">
                <ControlLabel>Login Here</ControlLabel>
                {/*   */}
            </FormGroup>{' '}
            <FormGroup controlId="formInlineUsername">
                <ControlLabel>Username / NIN</ControlLabel>{' '}
                <FormControl type="text" placeholder="Username/ID Number" />
            </FormGroup>{' '}
            <FormGroup controlId="formInlinePassword">
                <ControlLabel>Password</ControlLabel>{' '}
                <FormControl type="password" placeholder="Your password" />
            </FormGroup>{' '}
            <Button type="submit" className="loginButton" bsStyle="success" fill href='/admin/dashboard'>Sign In</Button>
            <Row className="helpSection">
                <Col xs={12} md={6} lg={6}>
                    <small><a href='/register'>Register</a></small>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <small>Forgot your password</small>
                </Col>
            </Row>
        </Form>
    );
  }
}

export default LoginForm;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col, ListGroup, ListGroupItem, FormGroup,  FormControl, ControlLabel } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import avatar from "assets/img/faces/user.png";

class GetLicence extends Component {
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Fill in the form"
                content={
                  <form>
                    <FormInputs 
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Select Vessel",
                          type: "select",
                          bsClass: "form-control",
                          placeholder: "Select Vessel",
                          defaultValue: "",
                          options: ['Choose vessel', 'Vessel 1', 'Vessel 2', 'Vessel 3', 'Vessel 4'],
                          disabled: false
                        },                      
                      ]}
                    />
                   <FormInputs 
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Select Route",
                          type: "select",
                          bsClass: "form-control",
                          placeholder: "Select Route",
                          defaultValue: "",
                          options: ['Choose Route','Portbell - Bukakata', 'Nsonga - Ntoroko'],
                          disabled: false
                        },                      
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Select Bank",
                          type: "select",
                          bsClass: "form-control",
                          placeholder: "Enter name",
                          options: ['Choose Bank', 'Stanbic', 'Bank of Africa', 'Centenary'],
                          defaultValue: ""
                        },
                      ]}
                    />
                    <Button bsStyle="info" pullRight fill type="submit">
                      Generate Payment Form  
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <Card
                  title="Guide Info"
                  category=""
                  stats=""
                  statsIcon="fa fa-history"
                  content={
                    <div className="quickLinks">
                      
                    </div>
                  }
                />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default GetLicence;

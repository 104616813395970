/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row,Col } from "react-bootstrap";
import './HomeFooter.scss';
import facebook from '../../assets/img/social/facebook.png'
import twitter from '../../assets/img/social/twitter.png'
import whatsapp from '../../assets/img/social/whatsapp.png'
import linkedin from '../../assets/img/social/linkedin.png'

class HomeFooter extends Component {
  render() {
    return (
      <footer className="footer homeFooter">
        <Grid>
          <Row>
              <Col xs={12} md={4} lg={4} className='sectionAbout'>
                <p className='sectionTitle'>Our Offices</p>
                <ul>
                  <li>Kampala Office 1 - asdpjfapdfadfasdfaksdf</li>
                  <li>Entebbe Office - adkfadfasfasfa</li>
                  <li>Mbalala Office - 'adksafpsdf'aksjdf'akdf</li>
                </ul>
                <div className="socialBtn">
                  <img src={facebook} alt='facebook'/>
                  <img src={twitter} alt='twitter' />
                  <img src={whatsapp} alt='whatsapp' />
                  <img src={linkedin} alt='linkedin'/>
                </div>
              </Col>
              <Col xs={12} md={4} lg={4} className='middleSection'>
                <div className="categories">
                  <p className="sectionTitle">Categories</p>
                  <ul>
                      <li>Producto</li>
                      <li>bout us</li>
                      <li>Testimonials</li>
                      <li>Price table</li>
                      <li>Crew</li>
                      <li>Portfolio</li>
                  </ul>
                </div>
                <div className="partners">
                  <p className="sectionTitle">Partners</p>
                  <ul>
                      <li>Lorem Ipsum</li>
                      <li>Lorem Ipsum</li>
                      <li>Lorem Ipsum</li>
                  </ul>
                </div>
              </Col>
              <Col xs={12} md={6} lg={4}></Col>
            </Row>
        </Grid>
      </footer>
    );
  }
}

export default HomeFooter;

import React from 'react';
import './Home.scss';
import { Grid, Row, Col, Image } from "react-bootstrap";
import {Helmet} from "react-helmet";

import { LoginForm } from "components/LoginForm";
import HomeHeader from "components/header";
import HomeFooter from 'components/Footer/HomeFooter';
import loginBanner  from "assets/img/loginBox.jpg";
import homeBanner  from "assets/img/banner.png";
import favicon  from "assets/img/court_of_arms.jpg";


function HomePage() {
  return (
    <div className="homePage">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Home - MOWT Self Service Portal</title>
            <link rel="canonical" href="http://mowt.cml.ug" />
            <link rel="icon" href={favicon} />
            <link rel="apple-touch-icon" href={favicon} />
            <meta
                name="description"
                content="Minsitry of Works & Transport Uganda"
            />
        </Helmet>
        <HomeHeader/>
        <Grid className='mainContent'>
            <Row className="show-grid">
                <Col xs={12} md={8} lg={8}>
                    <br /><br />
                    <div className="bannerContainer">
                        <div className="bannerTitle">
                            Self Service Portal
                        </div>
                        {/* <div className="bannerCover" >
                            <div className="bannerTitle">
                                Ministry of Works & Transport <br></br>
                                Self Service Portal
                            </div>
                        </div> */}
                        <Image src={loginBanner} />
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className='loginSection'>
                        <LoginForm />
                    </div>
                </Col>
            </Row>
        </Grid>
        <HomeFooter />         
    </div>
  );
}

export default HomePage;

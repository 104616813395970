/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Table, Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card";

import Button from "components/CustomButton/CustomButton";

class Icons extends Component {
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={8} mdOffset={2}>
              <Card
                hCenter
                title="More MOWT Useful Information"
                category="Are you looking for more useful links & information & quick links?"
                ctTableResponsive
                ctTableFullWidth
                ctTableUpgrade
                content={
                  <Table>
                    <thead>
                      <tr>
                        <th className="text-left">#</th>
                        <th className="text-left"/>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Register New Vehicle</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Apply for driving Permit</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Apply for an operator licence</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Transfer Vehicle Ownership</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Change Vehichle Purpose</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Apply for SDG Inspection</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Make payments for permit</td>
                      </tr>
                      </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Icons;

import React from 'react';
import './HomeHeader.scss';
import { Grid, Row, Col, Image } from "react-bootstrap";
import HomeNavbar from "components/Navbars/HomeNavbar";
import { SearchForm } from "components/SearchForm";
import brandImg  from "assets/img/court_of_arms.jpg";

function HomeHeader() {
  return (
      <div className="homeHeader">
        <Grid className='headerSubSection'>
            <Row className="show-grid">
                <Col xs={12} md={12} lg={12} className='topMenu'>
                    <Image src={brandImg} className='logoImage' /> 
                    <div className='title'>
                        <p>ministry of works & transport</p>
                    </div>
                </Col>
            </Row>
        </Grid>
        <HomeNavbar brandText='Department of Maritime Administration'/>
        {/* <HomeNavbar brandText='Ministry of Work & Transport' brandImage={brandImg}/> */}
        <Grid className='flagBanner'>
            <Row><Col xs={12} md={12} lg={12} className='flag black'></Col></Row>
            <Row><Col xs={12} md={12} lg={12} className='flag yellow'></Col></Row>
            <Row><Col xs={12} md={12} lg={12} className='flag red'></Col></Row>
        </Grid>
      </div>
  );
}

export default HomeHeader;

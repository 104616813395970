/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Form, FormGroup, FormControl, Button } from "react-bootstrap";
import './SearchForm.scss'

export class SearchForm extends Component {
  render() {
    return (
        <Form inline>
            <FormGroup controlId="formInlineEmail">
                <FormControl type="text" placeholder="Enter search key" className='searchInput'/>
            </FormGroup>{' '}
            <Button type="submit" bsStyle="success">SEARCH</Button>
        </Form>
    );
  }
}

export default SearchForm;

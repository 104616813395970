/*!
=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================
* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";

import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar,
  optionRouteCapacity,
  routeCapacity,
  legendCapacity
} from "variables/Variables.jsx";

class ReportsPage extends Component {
  createLegend(json) {
    console.log(json)
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      if(type.includes('#')){
        legend.push(<i className='labelBullet' style={{background: json["types"][i]}} key={i}></i>);
      }else {
        legend.push(<i className={type} key={i}>.</i>);
      }
      legend.push(" ");
      legend.push(<glabel>{json["names"][i]}</glabel>);
      legend.push(" ");
    }
    console.log(legend)
    return legend;
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="Registered Vessels"
                statsValue="3,000"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="YTD"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Expired Licences"
                statsValue="1,345"
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="YTD"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-info text-danger" />}
                statsText="Life Jackets Issued"
                statsValue="2300"
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="YTD"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-twitter text-info" />}
                statsText="Vessel Inspected"
                statsValue="245"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="YTD"
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Monthly Vessel Inspection"
                category="2020"
                stats="Updated 3 minutes ago"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataSales}
                      type="Line"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendSales)}</div>
                }
              />
            </Col>
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Vessels Type Stats"
                category="Period of Jan 2020 - Aug 2020"
                stats="Last updated 11 Aug 21:45"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={dataPie} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendPie)}</div>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card
                id="chartActivity"
                title="Vessels Inspected"
                category="For the year of 2020"
                stats="Last updated 10 Aug 21:45"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                // legend={
                //   <div className="legend">{this.createLegend(legendBar)}</div>
                // }
              />
            </Col>
            <Col md={6}>
              <Card
                id="chartActivity"
                title="Capacity Per Route"
                category="For the year 2020"
                stats="Last updated 10 Aug 21:45"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={routeCapacity}
                      type="Bar"
                      options={optionRouteCapacity}
                      responsiveOptions={responsiveBar}
                    />

                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ReportsPage;
/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import './Alerts.scss'

export class AlertTips extends Component {

  render() {

    const alerts_box = [
      {
        title: 'Vessel 1 licence expiring in 24Hrs',
        type: 'danger'
      },
      {
        title: "New route has been added",
        type: 'primary'
      },
      {
        title: "Register a new vessel in a few steps",
        type: 'warning'
      }
    ];
    var Notif = [];

    for (var i = 0; i < alerts_box.length; i++) {
      Notif.push(
        <Alert key={i} variant={alerts_box[i].type}>
          {alerts_box[i].title}
        </Alert>
      );
    }

    return <div className='alertContainer'>{Notif}</div>;
  }
}

export default AlertTips;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import './registerForm.scss'
import avatar from "assets/img/faces/user.png";
import agents from "assets/img/agents.png";
import company from "assets/img/company.png";

export class RegisterForm extends Component {
    constructor(props, context) {
        super(props, context);
    
        this.handleChange = this.handleChange.bind(this);
    
        this.state = {
          value: [1]
        };
    }
    
    handleChange(e) {
        this.setState({ value: e });
    }
    
    render() {
        return (
            <div className='registerForm'>
                <Grid>
                    <Row>
                        <Col xs={12} lg={12} >
                            <div className="accounts">
                            <div>
                                <div className="account">
                                    <p className="accountImg">        
                                        <Image src={company} alt="..." className="company" circle/>
                                    </p>
                                    <h2 className="account-title">Company</h2>
                                    <ul className="account-features">
                                        <li><strong>Requirement</strong></li>
                                        <li>Certificate of Incoporation</li>
                                    </ul>
                                    <a href="index.html" className="account-button company">SIGN UP</a>
                                </div>
                                <div className='accountShadow'></div>
                            </div>
                            <div className="account account-tall">
                                <p className="accountImg">        
                                    <Image src={avatar} alt="..." circle/>
                                </p>
                                <h2 className="account-title">Individual</h2>
                                
                                <ul className="account-features">
                                    <li><strong>Requirement</strong></li>
                                    <li>National ID</li>
                                    <li></li>
                                </ul>
                                <a href="index.html" className="account-button individual">SIGN UP</a>
                            </div>
                            <div className="account">
                                <p className="accountImg">        
                                    <Image src={agents} alt="..." className='agent' circle/>
                                </p>
                                <h2 className="account-title">Agent</h2>
                                <ul className="account-features">
                                    <li><strong>Requirement</strong></li>
                                    <li>National ID</li>
                                    <li>Entity Authorization</li>
                                </ul>
                                <a href="index.html" className="account-button agent">SIGN UP</a>
                            </div>
                        </div>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default RegisterForm;

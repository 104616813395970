/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";

import { Grid, Row, Col, Button, ListGroup, ListGroupItem,Alert } from "react-bootstrap";
import { UserCard } from "components/UserCard/UserCard.jsx";
import avatar from "assets/img/faces/user.png";

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import { AlertTips } from "components/Alerts/Alerts.jsx";
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";

class Dashboard extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                link='/admin/register'
                bigIcon={<i className="pe-7s-pen text-warning" />}
                statsText="Add vessel"
                statsValue=""
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Vessel Section"
                cover='red'
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                link='/admin/get-licence'
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Get License"
                statsValue=""
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Payment Section"
                cover='black'
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                link='/admin/licences'
                bigIcon={<i className="pe-7s-note2 text-danger" />}
                statsText="View Licences"
                statsValue=""
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="Routes Section"
                cover='green'
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                link='/admin/dashboard'
                bigIcon={<i className="fa fa-twitter text-info" />}
                statsText="Access Resources"
                statsValue=""
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Resources Section"
                cover='green'
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
            <Card
                  title="Notifications"
                  category=""
                  stats="Updated 3 minutes ago"
                  statsIcon="fa fa-history"
                  content={
                    <div className="">
                      <AlertTips />
                    </div>
                  }
                />
            </Col>
            <Col md={4}>
              <Card
                  title="Quick Links"
                  category=""
                  stats=""
                  statsIcon="fa fa-history"
                  content={
                    <div className="quickLinks">
                      <ListGroup>
                        <ListGroupItem><a href='#'>Register your vessel</a></ListGroupItem>
                        <ListGroupItem><a href='#'>Apply for a xxxxxx</a></ListGroupItem>
                        <ListGroupItem><a href='#'>xxxxxx</a></ListGroupItem>
                        <ListGroupItem><a href='#'>xxxxxx</a></ListGroupItem>
                      </ListGroup>
                    </div>
                  }
                />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import UserProfile from "views/UserProfile.jsx";
import RegisterVessel from "views/RegisterVessel.jsx";
import RegisterCrew from "views/RegisterCrew.jsx";

import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";
import Icons from "views/Icons.jsx";
import Maps from "views/Maps.jsx";
import Notifications from "views/Notifications.jsx";
import Upgrade from "views/Upgrade.jsx";
import RegistrationSection from 'views/Register.jsx'
import ReportsPage from 'views/Reports'
import LicenceSection from 'views/licences'
import GetLicence from 'views/GetLicence'

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    title: 'Services',
    layout: "/admin"
  },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   icon: "pe-7s-user",
  //   component: UserProfile,
  //   title: 'My Profile',
  //   layout: "/admin"
  // },
  {
    path: "/register",
    name: "My Vessels",
    icon: "pe-7s-pen",
    component: RegisterVessel,
    title: 'Register a vessel',
    layout: "/admin"
  },  
  {
    path: "/crew",
    name: "My Crew",
    icon: "pe-7s-users",
    component: RegisterCrew,
    title: 'Register a crew',
    layout: "/admin"
  },
  {
    path: "/licences",
    name: "Licences",
    icon: "pe-7s-wallet",
    component: LicenceSection,
    title: 'My Licences',
    layout: "/admin"
  }, 
  {
    path: "/get-licence",
    name: "Get Routes Licence",
    icon: "pe-7s-download",
    component: GetLicence,
    title: 'Routes Section',
    layout: "/admin"
  },  {
    path: "/reports",
    name: "Reports",
    icon: "pe-7s-news-paper",
    title: 'Admin Reports',
    component: ReportsPage,
    layout: "/admin"
  },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "pe-7s-note2",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "pe-7s-news-paper",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "pe-7s-science",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "pe-7s-map-marker",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "pe-7s-bell",
  //   component: Notifications,
  //   layout: "/admin"
  // },
  // {
  //   upgrade: true,
  //   path: "/",
  //   name: "MORE INFO",
  //   icon: "pe-7s-rocket",
  //   component: Upgrade,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;

/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";

class RegistrationSection extends Component {
  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={6} sm={6}>
              <StatsCard
                link='#'
                bigIcon={<i className="pe-7s-car text-warning" />}
                statsText="Vehicles"
                statsValue="4"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Registered Vehicles"
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                link='#'
                bigIcon={<i className="pe-7s-note text-success" />}
                statsText="Register New Vehicle"
                statsValue=""
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Vehicle Registration Form"
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                link='#'
                bigIcon={<i className="pe-7s-download text-danger" />}
                statsText="Import Vehicle Details"
                statsValue=""
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="Import exiting vehicle details"
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                link='#'
                bigIcon={<i className="fa fa-twitter text-info" />}
                statsText="Update Ownership"
                statsValue=""
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Ownership Section"
              />
            </Col>
            <Col lg={6} sm={6}>
              <StatsCard
                link='#'
                bigIcon={<i className="pe-7s-medal text-info" />}
                statsText="SDG Inspection"
                statsValue=""
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Apply for SDG Inspection"
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>

            </Col>
            <Col md={4}>

            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default RegistrationSection;

import React from 'react';
import {Helmet} from "react-helmet";
import { Grid, Row, Col} from "react-bootstrap";
import { RegisterForm } from "components/RegisterForm";
import HomeHeader from "components/header";
import HomeFooter from 'components/Footer/HomeFooter';

import './register.scss';

function RegisterPage() {
  return (
      <div className="wrapper registerPage">
        <Helmet>
          <title>Register - MOWT Self Service Portal</title>
      </Helmet>
        <HomeHeader/>
        <Grid className=''>
            <Row className="show-grid pageCrumb">
                <Col xs={12} md={8} lg={8}>
                    <a className="prevPage" href='/'>Home</a> {'>'}
                    <span className='currentPage'>Register</span>
                </Col>
            </Row>
            <Row className="show-grid">
                <Col xs={12} md={8} lg={8} className='pageName'>
                    New User Registration
                </Col>
                <Col xs={12} md={12} lg={12}> 
                    <RegisterForm />
                </Col>
            </Row>
        </Grid>
        <HomeFooter />
    </div>
  );
}

export default RegisterPage;

import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import './HomeNavbarLinks.scss'

class HomeNavbarLinks extends Component {
  render() {
    const currentPath = window.location.pathname;

    return (
      <div>
        <Nav>
          <NavItem eventKey={1} href="#">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
          <NavItem eventKey={3} href="#">
            <i className="fa fa-search" />
            <p className="hidden-lg hidden-md">Search</p>
          </NavItem>
        </Nav>
        <Nav pullRight>
          {/* <NavDropdown
            eventKey={2}
            title="Login with"
            id="basic-nav-dropdown-right"
          >
            <MenuItem eventKey={2.1}>National ID</MenuItem>
            <MenuItem eventKey={2.2}>Company ID</MenuItem>
            <MenuItem eventKey={2.3}>Something</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={2.5} href="/register">Create Account</MenuItem>
          </NavDropdown> */}
          {!currentPath.includes('admin') ? 
          <NavItem eventKey={3} href="/registerz" className='registerBtn'>
            Register
          </NavItem>:
          <NavItem eventKey={3} href="/" className='registerBtn'>
            Logout
          </NavItem>}
        </Nav>
      </div>
    );
  }
}

export default HomeNavbarLinks;
